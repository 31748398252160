<template>

<BCard class="p-1">
  <component 
    :is="$route.params.item"
    :closeModal="closeModal"
    :openModal="openModal"
    :editObj="editObj"
  ></component>

</BCard>

</template>

<script>
import PresentersForm from '@/@core/components/forms/PresentersForm.vue';
import ShowsForm from '../@core/components/forms/ShowsForm.vue';
import {
  BCard, BCol
} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    'show': ShowsForm,
    'presenter': PresentersForm,
  },

  props: ['closeModal', 'openModal', 'editObj'],
}

</script>